.sidebar.ant-layout-sider {  
  background: #ffffff; /* Change to white background */
  border-right: 1px solid #e8e8e8; /* Light grey border to separate the sidebar */
  height: 100%;
  position: relative;
  left: 0;
  top: 0;
  bottom: 0;
}

.conversation-section {
  background: #fff; /* White background for the main content */
  height: 100%;
}

.selected-conversation {
  width: 100%; /* Ensure SelectedConversation takes up the whole width */
  height: 100%; /* Ensure SelectedConversation takes up the full height */
}

.app-header {
  background: #001529; /* Dark background for the header */
  color: white; /* White text color for the header */
  width: 100%;
}