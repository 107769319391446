.conversation-item {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border-bottom: 1px solid #e8e8e8;
  position: relative;
}

.conversation-item :global(.ant-list-item-action) {
  margin-left: 0 !important;
}

.conversation-item:hover,
.conversation-item.conversation-item--active {
  background-color: #f0f2f5;
  cursor: pointer;
}

.conversation-item-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.conversation-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.conversation-item-text {
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.conversation-item-date {
  font-size: 0.8em;
  color: #888;
}

.conversation-item-preview {
  width: 100%;
}

.conversation-item-message {
  display: block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.conversation-item--unread {
  background-color: #e6f7ff;
  border-left: 4px solid #1890ff;
  font-weight: bold;
}